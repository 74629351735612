import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache) {
  const _component_van_icon = _resolveComponent("van-icon");
  const _component_van_col = _resolveComponent("van-col");
  const _component_van_row = _resolveComponent("van-row");
  const _component_van_cell = _resolveComponent("van-cell");
  const _component_van_cell_group = _resolveComponent("van-cell-group");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_van_row, {
    class: "user-links"
  }, {
    default: _withCtx(() => [_createVNode(_component_van_col, {
      span: "6"
    }, {
      default: _withCtx(() => [_createVNode(_component_van_icon, {
        name: "pending-payment"
      }), _createTextVNode(" 待付款 ")]),
      _: 1
    }), _createVNode(_component_van_col, {
      span: "6"
    }, {
      default: _withCtx(() => [_createVNode(_component_van_icon, {
        name: "records"
      }), _createTextVNode(" 待接单 ")]),
      _: 1
    }), _createVNode(_component_van_col, {
      span: "6"
    }, {
      default: _withCtx(() => [_createVNode(_component_van_icon, {
        name: "tosend"
      }), _createTextVNode(" 待发货 ")]),
      _: 1
    }), _createVNode(_component_van_col, {
      span: "6"
    }, {
      default: _withCtx(() => [_createVNode(_component_van_icon, {
        name: "logistics"
      }), _createTextVNode(" 已发货 ")]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_van_cell_group, {
    class: "user-group"
  }, {
    default: _withCtx(() => [_createVNode(_component_van_cell, {
      icon: "records",
      title: "全部订单",
      "is-link": ""
    })]),
    _: 1
  }), _createVNode(_component_van_cell_group, null, {
    default: _withCtx(() => [_createVNode(_component_van_cell, {
      icon: "points",
      title: "我的积分",
      "is-link": ""
    }), _createVNode(_component_van_cell, {
      icon: "gold-coin-o",
      title: "我的优惠券",
      "is-link": ""
    }), _createVNode(_component_van_cell, {
      icon: "gift-o",
      title: "我收到的礼物",
      "is-link": ""
    })]),
    _: 1
  })]);
}